import React from 'react'
import { useState } from 'react';
import "./AboutUs.css"
import BlueNavbar from '../../Home/BlueNavbar';
import Navbar from "../../Home/Navbar"
import WebFooter from "../../Home/WebFooter"
import { useSelector } from 'react-redux';

export default function HoursandHolidays() {
    const navbarFont = useSelector((state) => state.navbarFontSlice);
    const paraFont = useSelector((state) => state.fontSizeSlice);
    const headingFont = useSelector((state) => state.headingFontSlice);
    const language = useSelector((state) => state.globalLanguage);
  return (
    <div>
      <Navbar/>
      <div className='NavbarTabs'><BlueNavbar/></div>
      <div className='bg-primarybg py-3 responsive-padding-for-all' style={{padding:'20px 150px'}} >
        <div className='mb-[20px]'>
            <span 
                className="font-semibold text-black mb-[20px] mx-auto w-fit" 
                style={{ fontSize: `${headingFont}px` }} 
                >
                {language ? "Hours and Holidays": "पुस्तकालय का समय और छुट्टियाँ" }
            </span>
        </div>

            <div className='bg-white p-3 rounded-3xl inner-responsive-padding'>
            <div className="border border-gray-500 rounded-2xl overflow-hidden">
                {/* Usual Working Hours */}
                <div className=" border-gray-500 " style={{ fontSize: `${paraFont}px`, lineHeight:'3rem'  }}>
                    <h2 className="font-semibold py-[10px] bg-navblue border border-gray-500">{language ? "Usual Working Hours": "सामान्य कामकाजी घंटे" }</h2>
                    <div className='py-3 flex px-5 table-inner-padding'>
                        <div className="w-1/2 text-left flex flex-col justify-center">
                            <div>{language ? "All Week Days": "सभी सप्ताह के दिन" }</div>
                        </div>
                        <div className="w-1/2 text-right flex flex-col justify-center text-aligning-class">
                            <div>{language ? "08:30 am - 12:00 midnight": "08:30am - 12:00 मध्यरात्रि" }</div>
                            <div>{language ? "24*7 (One week prior to exam till the end of exams)": "24*7 (परीक्षा से एक सप्ताह पहले से परीक्षा समाप्त होने तक)" }</div>
                        </div>
                    </div>
                </div>

                {/* Documents Circulation */}
                <div className=" border-gray-500" style={{ fontSize: `${paraFont}px`, lineHeight:'3rem' }}>
                    <h2 className="font-semibold py-[10px] bg-navblue border border-gray-500">{language ? "Documents Circulation": "दस्तावेज़ परिचालन" }</h2>
                    <div className='py-3 flex px-5 table-inner-padding'>
                        <div className="w-1/2 text-left flex flex-col justify-center">
                            <div> {language ? "General Books ": "सामान्य पुस्तकें" } (<a href="#" className="text-blue-600">{language ? " Monday to Friday ": "सोमवार से शुक्रवार" }</a>)</div>
                            <div>TBLS (<a href="#" className="text-blue-600">{language ? " Monday to Friday ": "सोमवार से शुक्रवार" }</a>)</div>
                        </div>
                        <div className="w-1/2 text-right flex flex-col justify-center text-aligning-class">
                            <div>10:30 am - 07:00 pm</div>
                            <div>09:30 am - 05:00 pm</div>
                        </div>
                    </div>
                </div>

                {/* Other Service Hours */}
                <div className=" border-gray-500" style={{ fontSize: `${paraFont}px` , lineHeight:'3rem'}}>
                    <h2 className="font-semibold py-[10px] bg-navblue border border-gray-500">{language ? "Other Service Hours ": "अन्य सेवा घंटे" }</h2>
                    
                    <div className='py-3 flex px-5 table-inner-padding'>
                        <div className="w-1/2 text-left flex flex-col justify-center">
                            <div> {language ? "Xeroxing ": "ज़ेरॉक्सिंग" }</div>
                            <div>{language ? "Printing": "प्रिंटिंग" }</div>
                        </div>
                        <div className="w-1/2 text-right flex flex-col justify-center text-aligning-class">
                            <div>02:30 pm - 05:30 pm</div>
                            <div>02:30 pm - 05:30 pm</div>
                        </div>
                    </div>
                </div>

                {/* Closed on Days */}
                <div className=" border-gray-500" style={{ fontSize: `${paraFont}px` , lineHeight:'3rem'}}>
                    <h2 className="font-semibold py-[10px] bg-navblue border border-gray-500">{language ? "The library will remain open on all days on the following days (Fully Closed)": "निम्नलिखित दिनों में पुस्तकालय सभी दिन खुला रहेगा (पूर्णतः बंद)" }</h2>
                    <div className='py-3 px-5 table-inner-padding'>
                        <div className="grid grid-cols-3 mt-2 grid-class1">
                            <div className='text-left'>{language ? "Republic Day": "गणतंत्र दिवस" }</div>
                            <div className='text-aligning-class'>{language ? "Independence Day": "स्वतंत्रता दिवस" }</div>
                            <div className='text-right text-aligning-class2'>{language ? "Gandhi Jayanti": "गांधी जयंती" }</div>
                        </div>
                    </div>
                </div>

                {/* Reduced Hours on Holidays */}
                <div style={{ fontSize: `${paraFont}px`, lineHeight:'3rem' }}>
                    <h2 className="font-semibold py-[10px] bg-navblue border border-gray-500">{language ? "MGCL will follow the reduced hours timing (08:30am - 05:30pm on following holidays)": "MGCL कम घंटों के समय का पालन करेगा (निम्नलिखित छुट्टियों पर सुबह 08:30 बजे से शाम 05:30 बजे तक)" }</h2>
                    <div className='py-3 px-5 table-inner-padding'>
                    <div className="grid grid-cols-3 mt-2 grid-class1">
                        <div  className='text-left'>{language ? "Holi": "होली" }</div>
                        <div className='text-aligning-class'>{language ? "Christmas Day": "क्रिसमस" }</div>
                        <div className='text-right text-aligning-class2'>{language ? "Gurunanak Jayanti": "स्वतंत्रता दिवस" }</div>
                        <div  className='text-left text-aligning-class'>{language ? "Deepawali": "दीपावली" }</div>
                        <div className='text-aligning-class2'>{language ? "Idul-Fitar": "ईदुल-फितर" }</div>
                    </div>
                    </div>
                </div>
                </div>

            </div>
      </div>
      <WebFooter/>
    </div>
  )
}
