import React, { useEffect, useState } from "react";
import arrow from "../../Assests/right-arrow.png"
import { showLang } from "../../Redux/Slices/globalLanguage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import NewViewAllPage from './NewViewAllPage';
import axios from "axios";

export default function NewsAndAnnouncement() {
  const [news, setNews] = useState([]);
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleViewAllClick = () => {
    navigate('/news-and-announcements', { state: { news } }); 
  };
  const fetchNews = async () => {
    try {
      const { data } = await axios.get(`http://localhost:8000/admin/getAllNews`);
      setNews(data.data);
      console.log(data.data);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <div
      className="bg-white p-6 newsandannounce-padding"
      style={{ padding: '20px 150px 20px' }}
    >
      <h2
        className="font-semibold text-black mb-[20px] mx-auto w-fit"
        style={{ fontSize: `${headingFont}px` }}
      >
        {language ? "News & Announcements" : "समाचार एवं घोषणाएँ"}
      </h2>

      <div
        className="space-y-5 bg-primarybg p-5 new-window"
        style={{ borderRadius: "24px" }}
      >
        {news.length > 0 ? (
        news
          .slice()
          .reverse()
          .slice(0, 5) 
          .map((item) => (
            <div
              key={item?._id}
              className="flex justify-between items-center bg-white news-windows p-4 shadow-sm"
              style={{ borderRadius: "16px" }}
            >
              <div>
                <p className=" text-left font-bold" style={{ fontSize: `${paraFont}px` }}>
                  {item?.time} | {item?.date} | {language ? item?.department : item?.branch2}
                </p>
                <h3
                  className="font-bold text-left mt-[10px]"
                  style={{ fontSize: `${paraFont}px` }}
                >
                  {language ? item?.title : item?.title2}
                </h3>
              </div>
              <div className="bg-navblue p-3">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          ))
        ) : (
          <p>Loading news...</p>
        )}
      </div>

      <div className="flex justify-end mt-4">
        <button
          className="flex items-center pl-4 bg-navblue text-black mr-[54px] button-margin font-semibold"
          style={{ fontSize: `${paraFont}px` }}
          onClick={handleViewAllClick}
        >
          <span>{language ? "View All" : "सभी देखें"}</span>
          <div className="bg-navblue p-3">
            <img src={arrow} alt="arrow" />
          </div>
        </button>
      </div>
    </div>
  );
}