import React, { useEffect, useState } from "react";
import "./AboutUs.css"
import BlueNavbar from "../../Home/BlueNavbar";
import Navbar from "../../Home/Navbar"
import WebFooter from "../../Home/WebFooter"
import { useSelector } from 'react-redux';
import librarian from "../../../Assests/librarian.jpeg"
import axios from "axios";
export default function OurTeam() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const [team, setTeam] = useState([]);
  const fetchTeam = async () => {
    try {
      const { data } = await axios.get(`http://localhost:8000/admin/getAllTeamMember`);
      setTeam(data.data);
      console.log(data.data);
    } catch (error) {
      console.error("Error fetching member:", error);
    }
  };
  useEffect(() => {
    fetchTeam();
  }, []);
  return (
    <div>
      <div className='min-h-screen'>
      <Navbar/>
      <div className='NavbarTabs'><BlueNavbar/></div>
      <div>
        <div className='bg-primarybg py-3'>
        <span 
                className="font-semibold text-black mb-[20px] mx-auto w-fit" 
                style={{ fontSize: `${headingFont}px` }} 
                >
                {language ? "MGCL Team": "MGCL टीम" } 
            </span>
        </div>
        
        <div className='bg-white flex justify-between items-end team-cards responsive-padding-for-all' style={{padding:'20px 150px 60px'}}>
              {/* Card 1 */}
              <div className="w-[49%] cursor-pointer team-card-width teammate1">
              <div className="bg-primarybg p-2 rounded-3xl shadow-md">
                  <div className="flex items-center  bg-white rounded-2xl ">
                    <img
                      src={librarian}
                      alt="Dr. Sanjeev K Sunny"
                      className="w-1/3 aspect-square rounded-2xl  mr-3"
                      />
                    <div className="text-left" style={{ fontSize: `${paraFont}px` }}>
                      <h3 className="text-lg font-bold ">{language ? "Dr. Sanjeev K Sunny": "डॉ. संजीव के सनी" } </h3>
                      <p>{language ? "Librarian (Officiating) ": "लाइब्रेरियन (कार्यवाहक)" }</p>
                      <p>Tel.: 01332-285939, 285239</p>
                      <p>library@iitr.ac.in</p>
                      <p>sksunnymgcl@iitr.ac.in</p>
                    </div>
                  </div>
                  </div>
                  </div>
              {/* Card 2 */}
              <div className="w-[49%] cursor-pointer team-card-width teammate2">
              <h2 className="font-semibold" style={{ fontSize: `${headingFont}px` }}> {language ? "CHAIRMAN LAC ": "चेनमैन एलएसी" }</h2>
              <div className="bg-primarybg  p-2 rounded-3xl shadow-md">
              <div className="flex items-center bg-white rounded-2xl ">
                <img
                  src={librarian} 
                  alt="Prof. (Dr.) Mahendra Singh"
                  className="w-1/3 aspect-square rounded-2xl mr-3"
                />
                <div  className="text-left" style={{ fontSize: `${paraFont}px` }}>
                  <h3 className="text-lg font-bold ">{language ? "Dr. Mahendra Singh": "डॉ. महेंद्र सिंह" }</h3>
                  <p>{language ? "Professor ": "प्रोफ़ेसर" }</p>
                  <p>{language ? "Civil Engineering Department": "सिविल इंजीनियरिंग विभाग" }</p>
                  <p>Tel.: 01332-285651</p>
                  <p>mahendra.singh@ce.iitr.ac.in</p>
                </div>
              </div>
              </div>
              </div>

        </div>
        <div className='bg-primarybg flex flex-wrap justify-between items-end team-cards responsive-padding-for-all' style={{padding:'0px 150px 60px'}}>
            {team.length > 0 ? (
                team.map((item, index) => (
              <div key={index} className="w-[49%] cursor-pointer mt-[60px] team-card-width">
                    <div className="bg-white p-2 rounded-3xl shadow-md">
                        <div className="flex items-center  bg-primarybg rounded-2xl ">
                          <img
                            src={item?.image}
                            alt={item?.image}
                            className="w-1/3 aspect-square rounded-2xl  mr-3"
                            />
                          <div className="text-left" style={{ fontSize: `${paraFont}px` }}>
                            <h3 className="text-lg font-bold ">{language ? item?.name: item?.name2 }</h3>
                            <p>{language ? item?.designation: item?.designation}</p>
                            <p>Tel.: {item?.number}</p>
                            <p>{item?.email}</p>
                            <p>{item?.official_email}</p>
                          </div>
                        </div>
                      </div>
                    </div>
               ))
                ) : (
                  <p>Loading members...</p>
                )}
        </div>
      </div>
      </div>
      <WebFooter/>
    </div>
  )
}
