import React, { useEffect, useState } from "react";
import "./AboutUs.css"
import BlueNavbar from "../../Home/BlueNavbar";
import Navbar from "../../Home/Navbar"
import WebFooter from "../../Home/WebFooter"
import { useSelector } from 'react-redux';
import buildingimg from "../../../Assests/IITR_img.jpg"
import libimg from "../../../Assests/iitrlib-img.jpeg"
import lib1 from "../../../Assests/lib1.jpg"
import axios from "axios";
import DOMPurify from 'dompurify';
export default function History() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const [isLoading, setIsLoading] = useState(true);
  const [sections, setSections] = useState({
    aboutIIT: null,
    aboutMGCL: null,
    historyMGCL: null,
  });

  const fetchContent = async () => {
    setIsLoading(true);

    try {
      const responses = await Promise.all([
        axios.get("http://localhost:8000/admin/getAboutUs/aboutIIT"),
        axios.get("http://localhost:8000/admin/getAboutUs/aboutMGCL"),
        axios.get("http://localhost:8000/admin/getAboutUs/historyMGCL"),
      ]);

      setSections({
        aboutIIT: {
          content: responses[0]?.data?.data?.html || null,
          image: buildingimg,
        },
        aboutMGCL: {
          content: responses[1]?.data?.data?.html || null,
          image: lib1,
        },
        historyMGCL: {
          content: responses[2]?.data?.data?.html || null,
          image: libimg,
        },
      });
    } catch (error) {
      console.error("Error fetching content:", error);
      setSections({
        aboutIIT: null,
        aboutMGCL: null,
        historyMGCL: null,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  const renderSection = (title, section) => {
    return (
      <div className="my-4 bg-white p-[20px] rounded-3xl inner-responsive-padding">
        {section?.image && (
          <img
            src={section?.image}
            alt={title?.english}
            className="w-full mb-[30px] rounded-2xl"
            style={{ aspectRatio: "1/0.4" }}
            onError={(e) => {
              if (title?.english === "About IIT Roorkee") e.target.src = buildingimg;
              else if (title?.english === "About MGCL") e.target.src = lib1;
              else e.target.src = libimg;
            }}
          />
        )}

        <span
          className="font-semibold text-black mb-[20px] mx-auto w-fit"
          style={{ fontSize: `${headingFont}px` }}
        >
          {language ? title?.english : title?.hindi}
        </span>

        <div className="mt-7">
          <div
            className="bg-primarybg rounded-2xl text-padding-inner p-4 text-justify font-semibold"
            style={{ fontSize: `${paraFont}px` }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(section?.content),
            }}
          />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full" />
      </div>
    );
  }

  return (
    <div>
      <div className="min-h-screen">
      <Navbar />
      <div className="NavbarTabs">
        <BlueNavbar />
      </div>

      <div className="bg-primarybg responsive-padding-for-all" style={{ padding: "20px 150px" }}>
        <span
          className="font-semibold text-black mb-[20px] mx-auto w-fit"
          style={{ fontSize: `${headingFont}px` }}
        >
          {language ? "History" : "इतिहास"}
        </span>

        {renderSection(
          {
            english: "About IIT Roorkee",
            hindi: "आईआईटी रूड़की के बारे में",
          },
          sections?.aboutIIT
        )}

        {renderSection(
          {
            english: "About MGCL",
            hindi: "MGCL के बारे में",
          },
          sections?.aboutMGCL
        )}

        {renderSection(
          {
            english: "History of MGCL",
            hindi: "MGCL का इतिहास",
          },
          sections?.historyMGCL
        )}
      </div>
      </div>
      <WebFooter />
    </div>
  );
}