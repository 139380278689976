import React, { useState, useEffect } from "react";
import frndimg from "../../Assests/1.jpeg";
import frndimg2 from "../../Assests/2.jpg";
import frndimg3 from "../../Assests/4.jpg";
import frndimg4 from "../../Assests/5.jpg";
import frndimg5 from "../../Assests/6.jpeg";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import { showLang } from "../../Redux/Slices/globalLanguage";
import { useDispatch, useSelector } from "react-redux";
export default function PhotoGallery() {
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);

  const images = [frndimg2, frndimg, frndimg3, frndimg4, frndimg5];

  const StyledSlider = styled(Slider)`
    .slick-slide > div > div {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  `;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 864,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="bg-primarybg p-6 shadow-lg servicebox-padding"
      style={{ padding: "30px 50px" }}
    >
      <h2
        className="font-semibold text-black mb-[20px] mx-auto w-fit"
        style={{ fontSize: `${headingFont}px` }}
      >
        {language ? "Photo Gallery" : "फोटो गैलरी"}
      </h2>

      <StyledSlider {...settings}>
        {images.map((imgSrc, index) => (
          <div key={index} className="flex justify-center items-center">
            <div className="text-center overflow-hidden rounded-3xl w-[85%] photogalleryw">
              <img
                src={imgSrc}
                alt={`Gallery item ${index + 1}`}
                className="rounded-lg"
              />
            </div>
          </div>
        ))}
      </StyledSlider>
    </div>
  );
}