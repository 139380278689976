import React, { useState, useEffect, useRef } from 'react';
import { showLang } from "../../../Redux/Slices/globalLanguage"
import Navbar from '../Navbar';
import BlueNavbar from '../BlueNavbar';
import WebFooter from '../WebFooter';
import "../LibraryMainPage.css"
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
export default function FAQ() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
    const paraFont = useSelector((state) => state.fontSizeSlice);
    const headingFont = useSelector((state) => state.headingFontSlice);
    const language = useSelector((state) => state.globalLanguage);
    const [faq, setFaq] = useState([]);
    const fetchFaq = async () => {
      try {
        const { data } = await axios.get(`http://localhost:8000/admin/getAllFaq`);
        setFaq(data.data);
        console.log(data.data);
      } catch (error) {
        console.error("Error fetching faqs:", error);
      }
    };
    useEffect(() => {
      fetchFaq();
    }, []);
      const [openIndex, setOpenIndex] = useState(null); 
      const toggleAccordion = (index) => {
        if (openIndex === index) {
          setOpenIndex(null); 
        } else {
          setOpenIndex(index); 
        }
      };
  return (
    <div>
      <div className='min-h-screen'>
      <Navbar/>
      <div className='NavbarTabs'><BlueNavbar/></div>
      <div>
            <div className='bg-primarybg responsive-padding-for-all' style={{padding:'0px 150px 20px'}}>
                
                    <div className='pt-10'>
                    <p 
                            className="font-semibold text-black mx-auto w-fit" 
                            style={{ fontSize: `${headingFont}px` }} 
                            >
                            {language ? "Frequently Asked Questions": "पूछे जाने वाले प्रश्न" }
                        </p>
                        <p 
                            className="font-thin text-gray-500 mx-auto w-fit" 
                            style={{ fontSize: `${paraFont}px` }} 
                            >
                             {language ? "Your doubt not listed? Drop mail at ": "आपका संदेह सूचीबद्ध नहीं है? यहाँ मेल छोड़ें" }
                             <a 
                                href="mailto:mgcl.iitr.ac.in/" 
                                className="text-black font-semibold ml-1"
                                >
                                mgcl.iitr.ac.in
                            </a>
                        </p>
                    </div>

            </div>    
            <div className="bg-white responsive-padding-for-all" style={{ padding: '20px 150px' }}>
            <div className="bg-primarybg p-5 rounded-3xl flex justify-center faq-padding-inner">
              <div className="w-[90%] faq-width">
                {faq.length > 0 ? (
                faq.map((item, index) => (
                  <div key={item?._id}>
                    <button
                      onClick={() => toggleAccordion(index)}
                      className={`w-full flex items-center justify-between p-3 focus:outline-none transition-all 
                      ${openIndex === index ? "" : "border-b border-black"}`}
                    >
                      <span className="mr-4" style={{ fontSize: `${paraFont}px` }}>
                        {openIndex === index ? '-' : '+'}
                      </span>
                      <span className="text-left font-semibold flex-grow" style={{ fontSize: `${paraFont}px` }}>
                        {language ? item?.question : item?.question2}
                      </span>
                    </button>
                    {openIndex === index && (
                      <div style={{ fontSize: `${paraFont}px` }} className="px-3 pb-3 text-left border-b flex border-black">
                        <div className="h-full"><span className="mr-4 font-semibold" style={{ color:'#F4F5FB', fontSize: `${paraFont}px` }}>
                          {openIndex === index ? '-' : '+'}
                        </span></div>
                        <div dangerouslySetInnerHTML={{ __html: language ? item?.answer : item?.answer2 }} />
                      </div>
                    )}
                  </div>
                  ))
                  ) : (
                    <p>Loading faq...</p>
                  )}
              </div>
            </div>
          </div>
      </div>
      </div>
      <WebFooter/>
    </div>
  )
}
